import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import ScrollAnimation from 'react-animate-on-scroll';
import { device } from '../lib/device';
import Header from '../components/Header';
import Footer from '../components/Footer';
import getAboutContent from '../hooks/getAboutContent';
import '../style/app.scss';

const AboutTemplate = styled.div`
	display: block;
	margin-bottom: 100px;
	@media ${device.tablet} {
		margin-bottom: 50px;
	}
	h1,
	h4,
	h2,
	p {
		font-family: var(--font_harmoniasanspro_light);
		font-weight: var(--fw_light);
		color: var(--brand_black);
		text-align: center;
		@media ${device.desktopHhd} {
			margin: 0px 20px;
		}
	}
	h1 {
		font-size: 60px;
		@media ${device.desktopHhd} {
			font-size: 40px;
			margin: 20px 0;
		}
	}
	img {
		width: 100%;
		height: auto;
	}
	h4 {
		font-size: 30px;
		color: var(--brand_blue);
		margin: 0;
		margin-top: 60px;
		@media ${device.desktopHhd} {
			font-size: 20px;
			margin-top: 30px;
		}
	}
	h2 {
		font-size: 60px;
		margin: 0;
		margin-bottom: 60px;
		margin-top: 20px;
		@media ${device.desktopHhd} {
			font-size: 40px;
			margin-bottom: 30px;
		}
	}
	p {
		font-size: 20px;
		font-weight: 100;
		text-align: left;
		@media ${device.desktopHhd} {
			padding-bottom: 20px;
		}
		@media ${device.mobile} {
			font-size: 18px;
		}
	}
`;

const About = () => {
	const data = getAboutContent();
	return (
		<>
			<Header />
			<ScrollAnimation animateIn="fadeInUp">
				<AboutTemplate>
					<div className="container_about">
						<h1>{data.title}</h1>
						<Img fluid={data.featuredImage.node.localFile.childImageSharp.fluid} />
						<h4>{data.acf.subtitle}</h4>
						<h2>{data.acf.title}</h2>
						<div
							dangerouslySetInnerHTML={{
								__html: data.content,
							}}
						/>
					</div>
				</AboutTemplate>
			</ScrollAnimation>
			<Footer />
		</>
	);
};

export default About;
